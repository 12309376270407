<template>
  <div class="container">
    <van-tabs v-model="brandId" type="card" @change="onChange">
      <van-tab :name="item.brandId" :title="item.name" v-for="item in brandList" :key="item.brandId"></van-tab>
    </van-tabs>
    <div class="product-cont">
      <div class="head-cont">
        <div class="left-cont">
          <div class="title">
            <van-icon name="fire" />特惠专区 限时加购
          </div>
          <van-count-down :time="time">
            <template #default="timeData">
              <span class="label">距离结束<template v-if="timeData.days > 0">{{ (timeData.days >= 10) ? '' :
                0 }}{{ timeData.days }}天</template></span>
              <span class="block">{{ (timeData.hours >= 10) ? '' : 0 }}{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">{{ (timeData.minutes >= 10) ? '' : 0 }}{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ (timeData.seconds >= 10) ? '' : 0 }}{{ timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
        <div class="right-cont">
          <!-- <div class="add-count">
            专区加购机会<van-tag round type="primary" color="#FE9AF0">{{addCount}}</van-tag>
          </div> -->
          <van-button type="primary" color="#FE9AF0" :to="'/special/orderList?brandId=' + brandId">查看特惠订单</van-button>
        </div>
      </div>
      <van-list v-model="loading" :finished="finished" :finished-text="''" @load="onLoad">
        <product-item :width="125" :key="index" :limit="true" :data-item="item" @step-change="onStepChange"
          @click-detail="handleDetail" v-for="(item, index) in datalist" />
        <div class="empty" v-if="finished && !datalist.length">
          <img src="../../../assets/images/empty/content.png" />
          <p>暂无商品~</p>
        </div>
      </van-list>

    </div>
    <div class="footer">
      <div class="shop-cart" @click="showCartLayer = true">
        <van-icon name="shopping-cart-o" />
      </div>
      <div class="total">
        共{{ total }}件，总价：<span>￥</span><span class="amount">{{ allAmount }}</span>
        <!-- <p>您还有<span>{{ addCount }}</span>次购买机会！</p> -->
      </div>
      <van-button type="primary" color="#C4B3FF" round @click="handleSettlement">去结算</van-button>
    </div>
    <van-popup v-model="showCartLayer" class="cus-popup" position="bottom" round>
      <div class="lay-head">
        <div class="title">已选商品</div>
        <div class="empty" @click="handleEmpty">
          <van-icon name="delete-o" size="20" />清空
        </div>
      </div>
      <div class="product-list">
        <div class="empty-data" v-if="finished && !datalist.length">
          <img src="../../../assets/images/empty/content.png" />
          <p>暂无数据</p>
        </div>
        <template v-for="(item, index) in datalist">
          <product-item :data-item="item" v-if="item.cartCount > 0" :key="index" :width="40" :norm="false" :effect="false"
            :stock="false" @step-change="onStepChange" />
        </template>
      </div>
    </van-popup>
    <van-popup round v-model="showSuperLayer" closeable>
      <super-prompt :user-info="userIdenty" :type="2" />
    </van-popup>
    <toolbar />
  </div>
</template>

<script>
import ProductItem from "../../../components/Product/Index.vue"
import SuperPrompt from "../../../components/Home/SuperPrompt"
import { Subtr, accMul } from "../../../utils/handle"
import toolbar from "../../../components/toolbar";
export default {
  name: "SpecialZoneList",
  components: { ProductItem, toolbar, SuperPrompt },
  data() {
    return {
      brandId: 3,
      brandList: [
        { brandId: 3, name: "美妆", addCount: 0, time: 0 },
        { brandId: 4, name: "大健康", addCount: 0, time: 0 }
      ],
      loading: false,
      finished: false,
      ps: 10,
      pg: 0,
      addCount: 0,
      total: 0,
      allAmount: 0,
      datalist: [],
      balanceInfo: null,
      time: 0,
      showCartLayer: false,
      userIdenty: null,
      showSuperLayer: false,
      activeInfo: []
    }
  },
  created() {
    this.getActivityInfo();
  },
  methods: {
    onChange() {
      this.pg = 0;
      this.datalist = [];
      this.loading = false;
      this.finished = false;
      let item = this.brandList.find(it => it.brandId === this.brandId);
      this.addCount = item.addCount;
      this.time = item.time;
    },
    onLoad() {
      this.pg++;
      this.getUserIdentityV4();
      this.get("/OrderGoods/PreferentialZone/GetPreferentialZoneGoodsList", {
        brandId: this.brandId,
      }, 2).then(res => {
        if (res.code === 1) {
          let list = res.response || [];
          let storeListStr = window.localStorage.getItem("specialCartList") || '';
          let hisList = storeListStr ? JSON.parse(storeListStr) : [];
          list.forEach(item => {
            item.brandId = this.brandId;
            let good = hisList.find(it => it.goodsId === item.goodsId);
            item.cartCount = good ? good.cartCount : 0;
          });
          this.calcCartInfo(list);
          this.datalist = this.datalist.concat(list);
        } else {
          this.$toast(res.msg);
        }
        this.finished = true;
        this.loading = false
      });
    },
    //获取用户身份
    getUserIdentityV4: function () {
      this.get("/TeamAgent/AgentInfo/HomeIdentity_V4").then(json => {
        if (json.code === 1) {
          //0：待审核，1：已审核， 3：未建立经销体系 4：黑名单，5：取消授权
          // userIdentityV4  新人-1 超V 0 代理1 取消授权2
          this.userIdenty = json.response;
        }
      })
    },
    //获取限时特惠信息
    getActivityInfo() {
      this.get("/OrderGoods/PreferentialZone/GetPreferentialZone", {
        brandId: this.brandId,
        v: 3
      }, 2).then(res => {
        if (res.code === 1) {
          this.activeInfo = res.response;
          this.checkIsInActivity();
        }
      })
    },
    //检查是否再活动中
    checkIsInActivity() {
      let list = this.activeInfo;
      let brandList = this.brandList;
      const brandId = this.brandId;
      list.forEach(item => {
        let time = item.endTimes - +new Date();
        let idx = brandList.findIndex(it => it.brandId === item.brandId);
        if (time <= 0) {
          brandList.splice(idx, 1);
        } else if (idx >= 0) {
          brandList[idx].addCount = item.count;
          brandList[idx].time = time;
        }
      });
      if (brandList.length === 0) {
        this.$dialog.alert({
          title: '提示',
          className: 'purple-alert',
          message: '特惠专区为限时加购\n该时段已结束，敬请期待下次开放时间！',
          confirmButtonText: "返回首页",
          confirmButtonColor: '#FFF'
        }).then(() => {
          this.brandId = brandId === 4 ? 3 : 4;
          this.onChange();
        }).catch(() => {
          this.$router.push("/")
        });
      } else {
        let item = brandList[0];
        this.addCount = item.addCount;
        this.time = item.time;
        if (item.brandId !== brandId) {
          this.brandId = item.brandId;
          this.onChange();
        }
      }
    },
    onStepChange() {
      let list = this.datalist;
      let total = 0, allAmount = 0;
      let shopCart = [];
      list.forEach(it => {
        if (it.cartCount > 0) {
          shopCart.push(it);
          total += it.cartCount;
          allAmount += accMul(it.cartCount, it.goodsPrice);
        }
      });
      window.localStorage.setItem("specialCartList", JSON.stringify(shopCart));
      this.total = total;
      this.allAmount = allAmount;
    },
    calcCartInfo(list) {
      let totalCount = 0, totalAmount = 0;
      list.forEach(it => {
        if (it.cartCount > 0) {
          totalCount += it.cartCount;
          totalAmount = Subtr(totalAmount, -accMul(it.cartCount, it.goodsPrice))
        }
      });
      this.total = totalCount;
      this.allAmount = totalAmount;
    },
    //点击查看详情
    handleDetail(item) {
      this.$router.push("/special/detail?goodId=" + item.goodsId + "&brandId=" + this.brandId);
    },
    //清空
    handleEmpty() {
      this.datalist.forEach(item => {
        item.cartCount = 0;
      })
      this.total = 0;
      this.allAmount = 0;
      this.showCartLayer = false;
      window.localStorage.setItem("specialCartList", '');
    },
    //点击结算
    handleSettlement() {
      if (this.addCount <= 0) {
        if (this.userIdenty) {  //判断超V 提示小程序下单
          if (this.brandId === 4 && this.userIdenty.jkLevel <= 2) {
            return this.showSuperLayer = true;
          }
        }
        let brandName = this.brandId === 4 ? '蔓薇' : "美妆"
        return this.$dialog.confirm({
          title: '提示',
          className: 'purple-confirm',
          message: '60天内您没有有效的' + brandName + '订单，\n不能购买特惠专区商品哦。',
          confirmButtonText: '立即下单',
          confirmButtonColor: '#B485FF'
        }).then(() => {
          window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?brandId=4"
        }).catch(() => { });
      }
      let arr = [];
      this.datalist.forEach(item => {
        if (item.cartCount > 0) {
          arr.push(`${item.goodsId}_${item.cartCount}`);
        }
      })
      if (!arr.length) return this.$toast("请选择要结算的商品");
      this.$router.push(`/special/confirm?brandId=${this.brandId}&goods=${encodeURIComponent(arr.join("#"))}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
  height: 100%;
  box-sizing: border-box;
  background-color: #d1c1ff;

  .van-tabs {
    padding-bottom: 23px;

    /deep/.van-tabs__wrap {
      .van-tabs__nav {
        border: none;
        justify-content: center;
        background: transparent;
      }

      .van-tab {
        width: 90px;
        color: #fff;
        flex: none;
        margin-right: 60px;
        -webkit-flex: none;
        -webkit-box-flex: none;
        border-radius: 22px;
        border: 1px solid #ffffff;

        &.van-tab--active {
          color: #8a62ca;
          background-color: #fff;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .product-cont {
    height: calc(100% - 53px);
    border-top-left-radius: 10px;
    background-color: #fff;

    .head-cont {
      display: flex;
      padding: 0 10px 10px;
      position: relative;
      justify-content: space-between;

      &:before {
        content: '';
        position: absolute;
        top: -8px;
        left: 0;
        right: 95px;
        bottom: 10px;
        z-index: 1;
        background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20220329173247674.png') no-repeat;
        background-size: 100% 100%;
      }

      .left-cont {
        width: calc(100% - 120px);
        position: relative;
        z-index: 10;
        padding-bottom: 10px;

        .title {
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .van-count-down {
          padding-top: 5px;
          color: #ffffff;
          font-size: 12px;

          .label {
            padding-right: 5px;
          }

          .colon {
            padding: 0 2px;
          }

          .block {
            color: #fb8aff;
            width: 17px;
            height: 17px;
            text-align: center;
            line-height: 17px;
            display: inline-block;
            background-color: #fff;
          }
        }
      }

      .right-cont {
        padding-top: 10px;

        .add-count {
          color: #fe9af0;

          .van-tag {
            margin-left: 3px;
          }
        }

        .van-button {
          height: 16px;
          padding: 0 5px;
          font-size: 10px;
          border-radius: 9px;
        }
      }
    }
  }

  .van-list {
    height: calc(100% - 80px);
    overflow-y: auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    /deep/.van-list__finished-text {
      color: #fff;
      padding-top: 20%;
    }

    .good-item {
      border-bottom: 1px solid #d1c1ff;

      &:last-child {
        border-bottom: none;
      }
    }

    .empty {
      padding: 20px 0;
      text-align: center;
      background-color: #fff;

      img {
        width: 60%;
      }

      p {
        color: #999;
        padding-top: 10px;
        font-size: 14px;
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    display: flex;
    z-index: 9999;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    .shop-cart {
      position: absolute;
      top: -10px;
      left: 16px;
      width: 50px;
      height: 50px;
      color: #fff;
      font-size: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 50px;
      background-color: #c4b3ff;
    }

    .total {
      padding-left: 59px;

      span {
        color: #c4b3ff;
      }

      .amount {
        font-size: 16px;
        font-weight: bold;
      }

      p {
        color: #999999;
        font-size: 10px;
      }
    }

    .van-button {
      height: 33px;
      padding: 0 23px;
    }
  }

  .cus-popup {
    padding-bottom: 50px;

    .lay-head {
      display: flex;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      justify-content: space-between;

      .empty {
        color: #999999;

        .van-icon {
          display: inline-block;
          vertical-align: sub;
          padding-right: 5px;
        }
      }
    }

    .product-list {
      max-height: 400px;
      overflow-y: auto;

      .good-item {
        padding: 10px;
        border-bottom: 1px solid #f1f1f1;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.iphoneX {
  .container {
    .van-list {
      padding-bottom: 84px;
    }
  }

  .cus-popup {
    padding-bottom: 84px;
  }

  .footer {
    padding-bottom: 34px;
  }
}
</style>